import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const DivorciosPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Divorcios" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Divorcios"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Procesamos su divorcio en Guatemala"
      >
        <p>
        El fundamento legal lo encontramos en el libro tercero del Código Civil de Guatemala.
        </p>
        <p>
        La ley señala dos formas para obtener su divorcio:
        </p>
        <p>
      <ol>
       <li><strong>La primera a través de un Juicio Voluntario de divorcio, es decir por mutuo
         acuerdo de ambas partes y en forma conjunta se presenta una demanda de divorcio ante
         el Juez de Familia donde contrajeron matrimonio en Guatemala.<br>
        </br></strong></li>
	     <li><strong>La Segunda a través de un Juicio Ordinario de Divorcio, se da cuando una
         de las partes no está de acuerdo en dar el divorcio y se somete a decisión del Juez
         de Familia donde usted contrajo matrimonio para que este resuelva disolver su matrimonio.<br>
        </br></strong></li>	     
        </ol>
        </p>
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default DivorciosPage

